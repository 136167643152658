<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import Multiselect from "vue-multiselect";
import router from "@/router"
import { ref, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";

export default {
  page: {
    title: "Add Customer",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ValidationProvider,
    ValidationObserver, 
    Multiselect
  },
  data() {
    return {
      title: "Add Customer",
      defaultNull: null,
      is_payleter : [
        {
            descr_id: 'Ya',
            code: 1
        },
        {
            descr_id: 'Tidak',
            code: 0
        },
      ],
    };
  },
setup(){
      const { $get } = useHttp()
      const { $post } = useHttp()
      const masterRef = ref(null)

      const form = ref({
      name: '',
      address:'',
      creditLimit:'',
      contactName:'',
      contactPhone:'',
      email:'',
      is_payleter:'',
      top_customer:'',
      
      })
      const LOV = ref({
       top_customer: []

     })

      const getTopCust = async () => { 
      const {data} = await $get({ url: 'master/codes/category/TPC'})
      LOV.value.top_customer = data
      
      }
      const validationForm = async () => {

        if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){

        let dataSubmit = {
        name : form.value.name,
        address : form.value.address,
        // creditLimit : form.value.creditLimit,  
        contactName : form.value.contactName,
        contactPhone : form.value.contactPhone,
        email : form.value.email,
        isPayleter : form.value.is_payleter.code,
        topCustomer : (form.value.is_payleter.code == 1)?form.value.top_customer.lbc_code:null
        }

      $post({
        url: 'master/customers/customers-add',
        data: dataSubmit
      })
      masterRef.value.reset();
      router.push({ name: 'appsmd-customer-list' })
      masterRef.value.reset();
       }
     }
    }

 const validationDetail = ()=> {
     if(
       form.value <1 ||
       form.value === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    onMounted(() => {
      getTopCust()
    })

    return {
      LOV,
      form,
      validationForm,
      required,
      masterRef
    }
},

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
               <validation-observer ref="masterRef">
                <b-form @submit.prevent>
                  <div class="form-group mb-3">
                        <label>Company Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Address</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.address"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Contact Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact Name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.contactName"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Contact Phone</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="contact Phone"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.contactPhone"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Email Notification</label><br />
                        <!-- <validation-provider
                        #default="{ errors }"
                        name="email Notification"
                        rules="required"
                        > -->
                        <b-form-input id="" name="" value="" v-model="form.email"></b-form-input>
                         <span>Separated with comma (',')</span>
                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                  </div>
                  <div class="form-group mb-3">
                      <label>Paylater</label><br />
                        <multiselect v-model="form.is_payleter" :options="is_payleter" label="descr_id" track-by="descr_id"></multiselect>
                  </div>
                  <div class="form-group mb-3" v-if="form.is_payleter.code == 1">
                      <label>TOP</label><br />
                      <multiselect v-model="form.top_customer" :options="LOV.top_customer" label="descr_id" track-by="descr_id" ></multiselect>
                  </div>
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Address"
                    label-for="customer_address"
                  >
                  <b-form-input v-model="form.address" id="" name="" value=""></b-form-input>
                  </b-form-group> -->

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Credit Limit"
                    label-for="customer_creditlimit"
                  >
                    <b-form-input v-model="form.creditLimit" id="" name="" value=""></b-form-input>
                  </b-form-group> -->

                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Name"
                    label-for="customer_contactname"
                  >
                    <b-form-input v-model="form.contactName" id="" name="" value=""></b-form-input>
                  </b-form-group> -->
                  
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Contact Phone"
                    label-for="customer_contactphone"
                  >
                     <b-form-input v-model="form.contactPhone" id="" name="" value=""></b-form-input>
                  </b-form-group> -->
                  
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Email Notification"
                    label-for="customer_contactphone"
                  >
                    <b-form-input v-model="form.email" id="" name="" value=""></b-form-input>
                    <span>Separated with comma (',')</span>
                  </b-form-group> -->
              </b-form>
              </validation-observer>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-customer-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>