var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-observer',{ref:"masterRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Company Name")]),_c('br'),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"","name":"","value":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Address")]),_c('br'),_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"","name":"","value":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Contact Name")]),_c('br'),_c('validation-provider',{attrs:{"name":"contact Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"","name":"","value":""},model:{value:(_vm.form.contactName),callback:function ($$v) {_vm.$set(_vm.form, "contactName", $$v)},expression:"form.contactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Contact Phone")]),_c('br'),_c('validation-provider',{attrs:{"name":"contact Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"","name":"","value":""},model:{value:(_vm.form.contactPhone),callback:function ($$v) {_vm.$set(_vm.form, "contactPhone", $$v)},expression:"form.contactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Email Notification")]),_c('br'),_c('b-form-input',{attrs:{"id":"","name":"","value":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',[_vm._v("Separated with comma (',')")])],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("Paylater")]),_c('br'),_c('multiselect',{attrs:{"options":_vm.is_payleter,"label":"descr_id","track-by":"descr_id"},model:{value:(_vm.form.is_payleter),callback:function ($$v) {_vm.$set(_vm.form, "is_payleter", $$v)},expression:"form.is_payleter"}})],1),(_vm.form.is_payleter.code == 1)?_c('div',{staticClass:"form-group mb-3"},[_c('label',[_vm._v("TOP")]),_c('br'),_c('multiselect',{attrs:{"options":_vm.LOV.top_customer,"label":"descr_id","track-by":"descr_id"},model:{value:(_vm.form.top_customer),callback:function ($$v) {_vm.$set(_vm.form, "top_customer", $$v)},expression:"form.top_customer"}})],1):_vm._e()])],1)],1)])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('div',{staticClass:"button-items"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('i',{staticClass:"ri-save-line align-middle ml-2"}),_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"danger","to":{ name: 'appsmd-customer-list'}}},[_c('i',{staticClass:"ri-arrow-left-line align-middle mr-2"}),_vm._v(" Back ")])],1)])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }